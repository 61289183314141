import React from "react";

export default function Sociallinks() {
	const socialLinks = [
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/debjyoti.acharjee',
			icon: 'fa fa-facebook-square'
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/debjyotia/',
			icon: 'fa fa-instagram'
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/DebjyotiA',
			icon: 'fa fa-twitter-square'
		},
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/in/debjyotiacharjee',
			icon: 'fa fa-linkedin-square'
		},
		{
			name: 'Gmail',
			link: 'mailto:itsme@debjyotiacharjee.com',
			icon: 'fa fa-envelope'
		}
	];

	return (
		<div className={`social-links mt-5`}>
			<ul className="list-inline">
				{socialLinks.map((data) => (
					<li className="list-inline-item mx-2" key={data.name}>
						<a href={data.link}><i className={data.icon} aria-hidden="true"></i></a>
					</li>
				))}
			</ul>
		</div>
	);
}