import React from "react";
import Layout from "../components/layout";
import Sociallinks from "../components/sociallinks";
import JSONData from "../../content/profile.json";
import { useQueryParam, StringParam } from "use-query-params";

const CompLE = ({ props }) => {
	// console.log('CompLE', props.text, props);
	return <div>
		{(props.icon) ? <div className="icon"><i className={props.icon} aria-hidden="true"></i></div> : null}
		{(props.date) ? <div className="date">{props.date}</div> : null}
		{(props.loc) ? <div className="loc"><i className="fas fa-map-marker-alt grey"></i> {props.loc}</div> : null}
		{(props.text) ? <div className="note">{props.text}</div> : null}
		{(props.links && props.links.length > 0) ?
			props.links.map((link, index) => {
				return <a className="link" key={index} href={link}><i className="far fa-images"></i> Photos & Videos</a>;
			})
			: null
		}
	</div>;
};

const CompEDU = ({ props }) => {
	// console.log('CompEDU', props.text, props);
	return <div>
		{(props.icon) ? <div className="icon"><i className={props.icon} aria-hidden="true"></i></div> : null}
		{(props.date) ? <div className="date">{props.date}</div> : null}
		{(props.loc) ? <div className="loc"><i className="fas fa-map-marker-alt grey"></i> {props.loc}</div> : null}

		{(props.school) ? <div className="school">{props.school}</div> : null}
		{(props.board) ? <div className="board">{props.board}</div> : null}
		{(props.degree) ? <div className="degree">{props.degree}</div> : null}
		{(props.major) ? <div className="major">{props.major}</div> : null}
		{/* {(props.score) ? <div className="score">{props.score}</div> : null} */}

		{(props.links && props.links.length > 0) ?
			props.links.map((link, index) => {
				return <a className="link" key={index} href={link}><i className="far fa-images"></i> Photos & Videos</a>;
			})
			: null
		}
	</div>;
};

const CompJOB = ({ props, type }) => {
	// console.log('CompJOB', props, type);
	return <div>
		{(props.logo) ?
			<img className="org-logo" src={props.logo}></img>
			: (props.icon) ? <div className="icon"><i className={props.icon} aria-hidden="true"></i></div> : null
		}

		{(props.date) ? <div className="date">{props.date}</div> : null}
		{(props.loc) ? <div className="loc"><i className="fas fa-map-marker-alt grey"></i> {props.loc}</div> : null}
		{(props.label) ? <div className="major">{props.label}</div> : null}

		{(props.org) ? <div className="org">{props.org}</div> : null}
		{(props.orgLink) ? <div className="board"><a className="org-link" href={props.orgLink}>{props.orgLink}</a></div> : null}
		{(props.designation) ? <div className="designation">Designation: <b>{props.designation}</b></div> : null}

		{(props.roles && props.roles.length > 0) ?
			<ul className={type === 'soc' ? 'd-none' : 'm-0'} >
				{props.roles.map((role, index) => {
					return <li className="role" key={index}>{role}</li>;
				})}
			</ul>
			: null
		}

		{(props.links && props.links.length > 0) ?
			props.links.map((link, index) => {
				return <a className="link" key={index} href={link}><i className="far fa-images"></i> Photos & Videos</a>;
			})
			: null
		}
	</div>;
};

const CompVAC = ({ props }) => {
	// console.log('CompVAC', props.text, props);
	return <div>
		{(props.icon) ? <div className="icon"><i className={props.icon} aria-hidden="true"></i></div> : null}
		{(props.date) ? <div className="date">{props.date}</div> : null}
		{(props.loc) ? <div className="loc"><i className="fas fa-map-marker-alt grey"></i> {props.loc}</div> : null}
		{(props.label) ? <div className="note">{props.label}</div> : null}
		{(props.text) ? <div className="note">{props.text}</div> : null}

		{(props.links && props.links.length > 0) ?
			props.links.map((link, index) => {
				return <a className="link" key={index} href={link}><i className="far fa-images"></i> Photos & Videos</a>;
			})
			: null
		}
	</div>;
};

export default function Profile() {
	const [type, setType] = useQueryParam('p', StringParam);

	if (type != 'pro' && type != 'soc') {
		setType('soc');
	}

	return (
		<Layout>
			<div className="d-flex justify-content-center">
				<div className="profile-type">
					<span className={type === 'soc' ? 'soc selected' : 'soc'} onClick={() => setType('soc')}>Social</span>
					<span className={type === 'pro' ? 'pro selected' : 'pro'} onClick={() => setType('pro')} >Professional</span>
				</div>
			</div>

			<div className={type === 'soc' ? 'd-none' : null}><a className="resume-link" href={JSONData.resume} target="_blank">
				<i className="fas fa-file-download" aria-hidden="true"></i>
				<span>Resume</span>
			</a></div>

			<div className="social-links-placeholder"><Sociallinks /></div>

			<div className="default-text-style">
				{JSONData.events.map((data, index) => {
					let style = '';
					if (data.align === 'left') style = 'left mb-0 col-sm-6 offset-sm-6';
					else if (data.align === 'right') style = 'right mb-0 col-sm-6';
					else if (data.align === 'center') style = 'center mb-0 col-sm-6 offset-sm-3';
					else style = 'blank mb-0 col-sm-6';

					if (type === 'pro') if (data.nodeAccess === 'private') style += ' d-none';

					return <div key={`item_${index}`} className={`list-unstyled dot ${style}`}>
						<span className="spot"></span>
						{data.nodeType === 'LE' ? <CompLE props={data} /> : null}
						{data.nodeType === 'EDU' ? <CompEDU props={data} /> : null}
						{data.nodeType === 'JOB' ? <CompJOB props={data} type={type} /> : null}
						{data.nodeType === 'VAC' ? <CompVAC props={data} /> : null}
					</div>
				})}
			</div>
		</Layout>
	)
};
